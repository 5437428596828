import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Dropdown,
  Pagination,
  Search,
} from '@makeably/creativex-design-system';
import Campaign, {
  campaignProps,
  optionProps,
} from 'components/connections/Campaign';
import ConfirmCampaignModal from 'components/connections/ConfirmCampaignModal';
import ConfirmCodeModal from 'components/connections/ConfirmCodeModal';
import AssetModal from 'components/molecules/AssetModal';
import { addToast } from 'components/organisms/Toasts';
import { getAuthenticityToken } from 'components/reusable/forms/AuthenticityTokenInput';
import {
  mapBrandCodeTaxonomyUnparsableCampaignPath,
  mapMarketCodeTaxonomyUnparsableCampaignPath,
  taxonomyUnparsableCampaignPath,
} from 'utilities/routes';

const CAMPAIGNS_PER_PAGE = 20;

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  canMapCodes: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  campaigns: PropTypes.arrayOf(campaignProps),
};

const defaultProps = {
  campaigns: [],
};

const statusLabel = {
  brand_not_found: 'Missing Brand',
  market_not_found: 'Missing Market',
  brand_and_market_not_found: 'Missing Brand and Market',
  unparseable_campaign_name: 'Un-parsable Campaign Name',
};

const missingBrandStatuses = ['brand_not_found', 'brand_and_market_not_found'];
const missingMarketStatuses = ['market_not_found', 'brand_and_market_not_found'];

function getLabel(key, value) {
  if (key === 'status') {
    return statusLabel[value];
  }

  return value;
}

function getOptions(key, campaigns) {
  const values = campaigns.reduce((vals, campaign) => vals.concat(campaign[key]), []);
  const sorted = [...new Set(values)].sort();
  return sorted.map((value) => ({
    label: getLabel(key, value),
    value,
  }));
}

function renderEmptyState() {
  return (
    <div>
      <Divider />
      <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
        Your search query produced no results
      </div>
    </div>
  );
}

async function submitCampaign(brandId, market, { id, channel }) {
  const url = taxonomyUnparsableCampaignPath(id);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('channel', channel);
  formData.append('brand_id', brandId);
  formData.append('market', market);

  const response = await fetch(url, {
    method: 'PATCH',
    body: formData,
  });
  return response.json();
}

async function mapBrandCode(brandCode, brandId, { id, channel }) {
  const url = mapBrandCodeTaxonomyUnparsableCampaignPath(id);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('channel', channel);
  formData.append('brand_code', brandCode);
  formData.append('brand_id', brandId);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

async function mapMarketCode(marketCode, market, { id, channel }) {
  const url = mapMarketCodeTaxonomyUnparsableCampaignPath(id);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('channel', channel);
  formData.append('market_code', marketCode);
  formData.append('market', market);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

function sortCampaigns(a, b, sortFilter) {
  if (sortFilter.value === 'spend') {
    return b.spend - a.spend;
  }
  return new Date(b.spendDate) - new Date(a.spendDate);
}

function UnparsableCampaigns({
  brandOptions,
  campaigns,
  canMapCodes,
  marketOptions,
}) {
  const [channelFilter, setChannelFilter] = useState(undefined);
  const [accountIdFilter, setAccountIdFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const [page, setPage] = useState(1);
  const [paginatedCampaigns, setPaginatedCampaigns] = useState([]);
  const [search, setSearch] = useState('');
  const [assetUrl, setAssetUrl] = useState('');
  const [assetVideoSource, setAssetVideoSource] = useState(undefined);
  const [mappedCampaign, setMappedCampaign] = useState(undefined);
  const [mappedBrandOption, setMappedBrandOption] = useState(undefined);
  const [mappedMarket, setMappedMarket] = useState(undefined);
  const [assetModalShown, setAssetModalShown] = useState(false);
  const [campaignModalShown, setCampaignModalShown] = useState(false);
  const [brandCodeModalShown, setBrandCodeModalShown] = useState(false);
  const [marketCodeModalShown, setMarketCodeModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState({
    label: 'Spend',
    value: 'spend',
  });

  const channelOptions = getOptions('channelLabel', campaigns);
  const accountIdOptions = getOptions('accountId', campaigns);
  const statusOptions = getOptions('status', campaigns);
  const hasFiltersOrSearch = channelFilter || accountIdFilter || statusFilter || search;
  const brandCode = mappedCampaign?.brandCode;
  const marketCode = mappedCampaign?.marketCode;
  const parseStatus = mappedCampaign?.status;

  const clearFilterAndSearch = () => {
    setChannelFilter(null);
    setAccountIdFilter(null);
    setStatusFilter(null);
    setSearch('');
  };

  const showAssetModal = (url, videoSource) => {
    setAssetUrl(url);
    setAssetVideoSource(videoSource);
    setAssetModalShown(true);
  };

  const showCampaignModal = (campaign, brandOption, market) => {
    setMappedCampaign(campaign);
    setMappedBrandOption(brandOption);
    setMappedMarket(market);
    setCampaignModalShown(true);
  };

  const onConfirmCampaign = async () => {
    setIsLoading(true);
    const data = await submitCampaign(mappedBrandOption.value, mappedMarket, mappedCampaign);

    if (data.success) {
      setCampaignModalShown(false);
      addToast('The campaign has been successfully mapped!');

      if (canMapCodes) {
        if (brandCode && missingBrandStatuses.includes(parseStatus)) {
          setBrandCodeModalShown(true);
        } else if (marketCode && missingMarketStatuses.includes(parseStatus)) {
          setMarketCodeModalShown(true);
        }
      } else {
        window.location.reload();
      }
    } else {
      addToast('Something went wrong! Please try again');
      window.location.reload();
    }
  };

  const onConfirmBrandCode = async () => {
    setIsLoading(true);
    const data = await mapBrandCode(brandCode, mappedBrandOption.value, mappedCampaign);

    if (data.success) {
      addToast(`Brand code ${brandCode} has been successfully added!`);
    } else {
      addToast(`Brand code ${brandCode} is already mapped to a brand`, { type: 'error' });
    }

    setBrandCodeModalShown(false);

    if (marketCode && missingMarketStatuses.includes(parseStatus)) {
      setMarketCodeModalShown(true);
    } else {
      window.location.reload();
    }
  };

  const onRejectBrandCode = () => {
    setBrandCodeModalShown(false);

    if (marketCode && missingMarketStatuses.includes(parseStatus)) {
      setMarketCodeModalShown(true);
    } else {
      window.location.reload();
    }
  };

  const onConfirmMarketCode = async () => {
    setIsLoading(true);
    const data = await mapMarketCode(marketCode, mappedMarket, mappedCampaign);

    if (data.success) {
      addToast(`Market code ${marketCode} has been successfully added!`);
    } else {
      addToast(`Market code ${marketCode} is already mapped to a market`, { type: 'error' });
    }

    window.location.reload();
  };

  useEffect(() => {
    const filtered = campaigns.filter(({
      channelLabel,
      accountId,
      status,
      name,
    }) => {
      if (channelFilter && channelLabel !== channelFilter.value) return false;
      if (accountIdFilter && accountId !== accountIdFilter.value) return false;
      if (statusFilter && status !== statusFilter.value) return false;
      if (search) {
        const term = search.toLowerCase();

        return accountId.toLowerCase().includes(term) || name.toLowerCase().includes(term);
      }

      return true;
    }).sort((a, b) => sortCampaigns(a, b, sortFilter));

    setFilteredCampaigns(filtered);
    setPage(1);
  }, [channelFilter, accountIdFilter, statusFilter, search, sortFilter]);

  useEffect(() => {
    setPaginatedCampaigns(
      filteredCampaigns.slice((page - 1) * CAMPAIGNS_PER_PAGE, page * CAMPAIGNS_PER_PAGE),
    );
  }, [filteredCampaigns, page]);

  return (
    <>
      <div className="u-flexColumn u-gap-24">
        <div className="u-flexRow u-gap-16 u-alignEnd">
          <Dropdown
            disabled={channelOptions.length === 0}
            label="Channel"
            menuProps={{ size: 'large' }}
            options={channelOptions}
            selected={channelFilter}
            size="small"
            onChange={(value) => setChannelFilter(value)}
          />
          <Dropdown
            disabled={accountIdOptions.length === 0}
            label="Ad Account ID"
            menuProps={{ size: 'large' }}
            options={accountIdOptions}
            selected={accountIdFilter}
            size="small"
            onChange={(value) => setAccountIdFilter(value)}
          />
          <Dropdown
            disabled={statusOptions.length === 0}
            label="Parsing Status"
            menuProps={{ size: 'large' }}
            options={statusOptions}
            selected={statusFilter}
            size="small"
            onChange={(value) => setStatusFilter(value)}
          />
          <Search
            value={search}
            onChange={setSearch}
          />
          { hasFiltersOrSearch && (
            <Button
              label="Clear"
              variant="tertiary"
              onClick={() => clearFilterAndSearch()}
            />
          ) }
          <Dropdown
            label="Sort By"
            options={[
              {
                label: 'Spend',
                value: 'spend',
              },
              {
                label: 'Date',
                value: 'spendDate',
              },
            ]}
            selected={sortFilter}
            size="small"
            onChange={setSortFilter}
          />
        </div>
        { paginatedCampaigns.length === 0 && renderEmptyState() }
        { paginatedCampaigns.map((campaign) => (
          <Campaign
            key={campaign.id}
            brandOptions={brandOptions}
            campaign={campaign}
            isLoading={isLoading}
            marketOptions={marketOptions}
            showAssetModal={showAssetModal}
            showCampaignModal={showCampaignModal}
          />
        )) }
        { paginatedCampaigns.length > 0 && filteredCampaigns.length > CAMPAIGNS_PER_PAGE && (
          <div className="u-flexRow u-justifyCenter">
            <Pagination
              currentPage={page}
              perPage={CAMPAIGNS_PER_PAGE}
              total={filteredCampaigns.length}
              onPageChange={setPage}
            />
          </div>
        ) }
      </div>
      <AssetModal
        isOpen={assetModalShown}
        url={assetUrl}
        videoSource={assetVideoSource}
        onClose={() => setAssetModalShown(false)}
      />
      <ConfirmCampaignModal
        brand={mappedBrandOption?.label}
        isOpen={campaignModalShown}
        market={mappedMarket}
        name={mappedCampaign?.name}
        onClose={() => setCampaignModalShown(false)}
        onConfirmCampaign={() => onConfirmCampaign()}
      />
      <ConfirmCodeModal
        code={brandCode}
        dimension="Brand"
        isOpen={brandCodeModalShown}
        mappedValue={mappedBrandOption?.label}
        onConfirmCode={() => onConfirmBrandCode()}
        onRejectCode={onRejectBrandCode}
      />
      <ConfirmCodeModal
        code={marketCode}
        dimension="Market"
        isOpen={marketCodeModalShown}
        mappedValue={mappedMarket}
        onConfirmCode={() => onConfirmMarketCode()}
        onRejectCode={() => window.location.reload()}
      />
    </>
  );
}

UnparsableCampaigns.propTypes = propTypes;
UnparsableCampaigns.defaultProps = defaultProps;

export default UnparsableCampaigns;
